import React from 'react'
import CenterWrapper from '../../../Components/CenterWrapper'
import Mbr from '../../../Components/Mbr'
export default function Introduction() {
    return (
        <section className="introduction">
            <CenterWrapper>
                <h2>
                    <b>M</b>usic with <b>U</b>nexpected
                    <br />
                    <b>A</b>udiences and <b>H</b>yper-connection
                </h2>
                <p>
                    <b>(주)무아엔터테인먼트</b>는<Mbr />
                    아티스트의 지속가능성을 위하여 모였습니다. <br />
                    K-Pop과 K-Tradition을 베이스로, <Mbr />
                    한국 예술의 창달을 통하여 함께하는 성장을 꿈꿉니다. <br />
                    재능있는 아티스트들에게 대중성과 자생력을 부여하며 <Mbr />
                    Social Impact를 함께 만들어갑니다.
                </p>
            </CenterWrapper>
            <div className="images center">
                <ul>
                    <li>
                        <img src="/assets/main1.png" alt="하오" />
                    </li>
                    <li>
                        <img src="/assets/main2.png" alt="관현악" />
                    </li>
                    <li>
                        <img src="/assets/main3.png" alt="경성구락부" />
                    </li>
                    <li>
                        <img src="/assets/main4.png" alt="루디" />
                    </li>
                </ul>
            </div>
        </section>
    )
}
