import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../../Components/Header'
import CenterWrapper from '../../Components/CenterWrapper'
import SubPage from '../../Components/SubPage'
import ButtonSquare from '../../Components/Button/ButtonSquare'
import Pbr from '../../Components/Pbr'
import Mbr from '../../Components/Mbr'
import PlatformButton from '../../Components/PlatformButton'
import { AlbumService } from '../../Services/AlbumService'
import { useAlbumDetail } from '../../Hooks/hooksAboutDb'

export default function AlbumDetail() {
    const { albumDetail, artistId, artistKoreanName, artist } = useAlbumDetail()
    // const [albumDetail, artistId, artistKoreanName] = useAlbumDetail()
    const albumDescription = albumDetail ? albumDetail.description.replace(/\n/g, '<br />') : ''

    return (
        <div>
            <Header />
            <SubPage>
                <CenterWrapper>
                    {albumDetail && (
                        <div className="albumDetail">
                            <div className="left">
                                <figure>
                                    <img src={albumDetail.coverImageUrl} alt="album.name" />
                                </figure>
                                <div className="buttons">
                                    <ButtonSquare to={`/artist/${artistId}`}>
                                        ABOUT <Mbr />
                                        {artist?.englishName}
                                    </ButtonSquare>
                                    <Pbr />
                                    <ButtonSquare to="/album" state={{ artistKoreanName }}>
                                        {' '}
                                        VIEW MORE <Mbr />
                                        ALBUMS
                                    </ButtonSquare>
                                </div>
                            </div>

                            <div className="right">
                                <div className="headingBox">
                                    <h3>{albumDetail.name}</h3>
                                    <h4>{albumDetail.albumArtistName}</h4>
                                </div>
                                <div className="platformButtonList">
                                    {albumDetail && albumDetail.streaming && albumDetail.streaming.appleVisible === 'on' && (
                                        <PlatformButton url={albumDetail.streaming.appleUrl} filename="applemusic" alt="애플뮤직" />
                                    )}
                                    {albumDetail && albumDetail.streaming && albumDetail.streaming.spotifyVisible === 'on' && (
                                        <PlatformButton url={albumDetail.streaming.spotifyUrl} filename="spotify" alt="스포티파이" />
                                    )}
                                    {albumDetail && albumDetail.streaming && albumDetail.streaming.melonVisible === 'on' && (
                                        <PlatformButton url={albumDetail.streaming.melonUrl} filename="melon" alt="멜론" />
                                    )}
                                    {albumDetail && albumDetail.streaming && albumDetail.streaming.bugsVisible === 'on' && (
                                        <PlatformButton url={albumDetail.streaming.bugsUrl} filename="bugs" alt="벅스" />
                                    )}
                                    {albumDetail && albumDetail.streaming && albumDetail.streaming.genieVisible === 'on' && (
                                        <PlatformButton url={albumDetail.streaming.genieUrl} filename="genie" alt="지니" />
                                    )}
                                    {albumDetail && albumDetail.streaming && albumDetail.streaming.youtubeVisible === 'on' && (
                                        <PlatformButton url={albumDetail.streaming.youtubeUrl} filename="youtube" alt="유튜브" />
                                    )}
                                </div>
                                <div className="credit">{albumDescription && <div dangerouslySetInnerHTML={{ __html: albumDescription }}></div>}</div>
                            </div>
                        </div>
                    )}
                </CenterWrapper>
            </SubPage>
        </div>
    )
}
