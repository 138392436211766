import React from 'react'
import { Link } from 'react-router-dom'

export default function Logo(props) {
  return (
    <div className={props.className}>
      <Link to="/">
        <img 
            src='/assets/logo.png' 
            alt='MUAH ENTERTAINMENT LOGO'
            className={props.className}
        />
      </Link>
    </div>
  )
}
