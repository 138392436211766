import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import SubPage from '../../Components/SubPage'
import CenterWrapper from '../../Components/CenterWrapper'
import { NoticeService } from '../../Services/NoticeService'
import { useNoticeDetail } from '../../Hooks/hooksAboutDb'

export default function NoticeDetail() {
    const { noticeDetail } = useNoticeDetail()
    // const [noticeDetail] = useNoticeDetail()

    // const params = useParams()
    // const id = params?.id
    // const [notice, setNotice] = useState(null)

    // useEffect(() => {
    //     if (!id) return
    //     NoticeService.read(id).then((Notice) => {
    //         setNotice(Notice)
    //     })
    // }, [id])

    return (
        <div className="noticeDetail">
            <SubPage>
                {noticeDetail && (
                    <>
                        <h2>NOTICE</h2>
                        <CenterWrapper>
                            <div className="noticeArticle">
                                <div className="titleArea">
                                    <h3>{noticeDetail.title}</h3>
                                    <span>{noticeDetail.publishedDate}</span>
                                </div>
                                <div className="contentArea" dangerouslySetInnerHTML={{ __html: noticeDetail.description }}>
                                    {/* {notice.description} */}
                                </div>
                            </div>
                        </CenterWrapper>
                        <CenterWrapper>
                            <Link to="/notice" className="buttonSquare list">
                                LIST
                            </Link>
                        </CenterWrapper>
                    </>
                )}
            </SubPage>
        </div>
    )
}
