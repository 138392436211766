import React, { useState, useEffect } from 'react'
import SubPage from '../../Components/SubPage'
import ArtistCard from '../../Components/ArtistCard'
import CenterWrapper from '../../Components/CenterWrapper'
import { ArtistService } from '../../Services/ArtistService'

export default function Artist() {
    const [artists, setArtists] = useState([])

    useEffect(() => {
        ArtistService.readAll().then((artists) => {
            setArtists(artists)
        })
    }, [])

    return (
        <div className="artist">
            <SubPage>
                <h2>ARTIST</h2>
                <CenterWrapper>
                    <ul className="artistList">
                        {artists.map((artist, index) => {
                            return (
                                <li>
                                    <ArtistCard nameLower={artist.id} nameUpper={artist.englishName} nameKo={artist.koreanName} />
                                </li>
                            )
                        })}
                    </ul>
                </CenterWrapper>
            </SubPage>
        </div>
    )
}
