import React from 'react'
import TitleSection from '../../../Components/TitleSection'
import AlbumList from '../../../Components/Album/AlbumList'
import ButtonSquare from '../../../Components/Button/ButtonSquare'
import CenterWrapper from '../../../Components/CenterWrapper'

export default function AlbumSection() {
  return (
    <section className="album">
        <CenterWrapper>
          <TitleSection>Album</TitleSection>
        </CenterWrapper>
        
        <AlbumList />
        <ButtonSquare to="/album">MORE</ButtonSquare>
    </section>
  )
}
