import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AlbumService } from '../Services/AlbumService'
import { ArtistService } from '../Services/ArtistService'
import { NoticeService } from '../Services/NoticeService'
import { AuditionService } from '../Services/AuditionService'

export function useAlbumDetail() {
    const { id } = useParams()
    const [album, setAlbum] = useState()
    const [artist, setArtist] = useState()
    const [artistId, setArtistId] = useState()


    useEffect(() => {
        id && AlbumService.read(id).then(setAlbum)
    }, [id])

    useEffect(() => {
        album &&
            ArtistService.readByKoreanName(album.artist).then((artist) => {
                setArtist(artist)
                setArtistId(artist.id)
            })
    }, [album])

    return {
        albumDetail: album,
        artist: artist,
        artistId: artistId,
        artistKoreanName: album?.artist,
    }
}

export function useArtistDetail() {
    const { id } = useParams()
    const [artistDetail, setArtistDetail] = useState()

    useEffect(() => {
        id && ArtistService.read(id).then(setArtistDetail)
    }, [id])

    return { artistDetail }
    // return [artistDetail]
}

export function useNoticeDetail() {
    const { id } = useParams()
    const [noticeDetail, setNoticeDetail] = useState(null)

    useEffect(() => {
        id && NoticeService.read(id).then(setNoticeDetail)
    }, [id])

    return { noticeDetail }
    // return [noticeDetail]
}

export function useAudition() {
    const [audition, setAudition] = useState()

    useEffect(() => {
        AuditionService.read().then(setAudition)
    }, [])

    return { audition }
    // return [audition]
}
