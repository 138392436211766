import React from 'react'
import CenterWrapper from './CenterWrapper'
import Logo from './Logo'
import Mbr from './Mbr'

export default function Footer() {
    return (
        <footer>
            <CenterWrapper>
                <Logo className="footerLogo" />
                <address>
                    법인명 : 주식회사 무아엔터테인먼트 <span className="hideOnmbl">I</span> <Mbr />
                    사업자등록번호 : 440-86-02189 I 대표이사 : 한승민
                    <br />
                    주소 : 서울특별시 강서구 등촌로13길 24, 2F <span className="hideOnmbl">I</span> <Mbr />
                    전화번호 : 0507-5455-5736 I 이메일 contact@muah.or.kr
                    <br />
                    MUAH Entertainment의 모든 Contents는 법적으로 보호 받고 있습니다.
                    <br />© 2023 MUAH Entertainment All Rights Reserved.
                </address>
                {/* <div className="sponsor">
            <table>
              <tbody>
                <tr>
                  <td className="title">후원</td>
                  <td>
                    <img className="logo-mcst" src="/assets/logo-mcst.png" alt="문화체육관광부 로고" />
                  </td>
                  <td>
                    <img className="logo-kams" src="/assets/logo-kams.png" alt="예술경영지원센터 로고" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
            </CenterWrapper>
        </footer>
    )
}
