import React from 'react'
import { Link } from 'react-router-dom'
import Tbr from './Tbr'

export default function ArtistCard(props) {
  return (
    <li className="artistCard" style={{backgroundImage: `url(/assets/${props.nameLower}_card.png)`}}>
        <Link to={`/artist/${props.nameLower}`}>
        <span className="en">{props.nameUpper}</span><Tbr />
        <span className="ko">{props.nameKo}</span>
        </Link>
    </li>
  )
}
