import React from 'react'
import { Link } from 'react-router-dom'

export default function NoticeListItem(props) {
  return (
    <li className="noticeListItem">
        <Link to={`${props.url}`}>
            <h3>{props.title}</h3>
            <span>{props.date}</span>
        </Link>
    </li>
  )
}
