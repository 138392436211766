import axios from 'axios';
import qs from 'qs';

class FirebaseFunctionsService {
  ROOT_URL;

  constructor() {
    this.ROOT_URL = process.env.NODE_ENV === 'production' ? 'https://asia-northeast3-muah-entertainment.cloudfunctions.net/api' : 'http://127.0.0.1:5001/muah-entertainment/asia-northeast3/api';
    // this.ROOT_URL = 'https://asia-northeast3-muah-entertainment.cloudfunctions.net/api';
  }

  async get(path, data) {
    try {
      return (await axios.get(`${this.ROOT_URL}${path}?${qs.stringify(data)}`))?.data;
    } catch (e) {
      console.log(`get 요청 에러 ${e}`);
    }
  }

  async post(path, data) {
    try {
      return (await axios.post(`${this.ROOT_URL}${path}`, data))?.data;
    } catch (e) {
      console.log(`post 요청 에러 ${e}`);
    }
  }
}

export const functionsService = new FirebaseFunctionsService();
