import React, { useState, useEffect } from 'react'
import CenterWrapper from '../../Components/CenterWrapper'
import SubPage from '../../Components/SubPage'
import AuditionModal from './AuditionModal'
import { AuditionService } from '../../Services/AuditionService'
import {useAudition, useNoticeDetail} from '../../Hooks/hooksAboutDb'

export default function Audition() {

    const {audition} = useAudition()
    // const [audition] = useAudition()

    return (
        <div className="audition">
            {audition &&
            <SubPage>
                <h2>AUDITION</h2>
                <CenterWrapper>
                    <div className="auditionContent" dangerouslySetInnerHTML={{__html : audition.generalAudition}}>
                    </div>
                </CenterWrapper>
            </SubPage>
            }
            <AuditionModal audition={audition} />
        </div>
    )
}