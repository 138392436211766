import React from 'react'
import CenterWrapper from '../../Components/CenterWrapper'
import SubPage from '../../Components/SubPage'
import Mbr from '../../Components/Mbr'

export default function Location() {
  return (
    <div className="location">
        <SubPage>
            <h2>LOCATION</h2>
            <CenterWrapper>
                <iframe className="map" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d101241.43717221166!2d126.80477760469513!3d37.536331524996605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9d54e0cbf969%3A0xa5223d3acff078fa!2zKOyjvCnrrLTslYTsl5TthLDthYzsnbjrqLztirg!5e0!3m2!1sko!2skr!4v1669232590771!5m2!1sko!2skr" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <p>
                    서울특별시 강서구 등촌로13길 24 2층 <Mbr />무아엔터테인먼트
                </p>
            </CenterWrapper>
        </SubPage>
    </div>
  )
}
