import { firestore } from '../Config/firebaseConfig'
import { collection, addDoc, getDocs, where, query, updateDoc, doc, orderBy, deleteDoc } from 'firebase/firestore'
import { classToPlain } from 'class-transformer'
import { AlbumModel } from '../Models/AlbumModel'
import { first } from 'lodash'

export class AlbumService {
    static async create(album) {
        const collectionRef = collection(firestore, 'albums')
        return await addDoc(collectionRef, classToPlain(album, { exposeUnsetFields: false }))
    }

    static async read(id) {
        const collectionRef = collection(firestore, 'albums')
        const q = query(collectionRef, where('id', '==', id))
        const result = await getDocs(q)
        const data = result.docs.map((x) => x.data())
        return first(data)
    }

    static async readAllByArtistName(artistName) {
        const collectionRef = collection(firestore, 'albums')
        const q = query(collectionRef, where("artist", "==", artistName), orderBy('releasedDate', 'desc'))
        const result = await getDocs(q)
        return result.docs.map((doc) => new AlbumModel(doc.data()))
    }

    static async readAll() {
        const collectionRef = collection(firestore, 'albums')
        const q = query(collectionRef, orderBy('releasedDate', 'desc'))
        const result = await getDocs(q)
        return result.docs.map((doc) => new AlbumModel(doc.data()))
    }

    static async update(id, data) {
        const collectionRef = collection(firestore, 'albums')
        const q = query(collectionRef, where('id', '==', id))
        const result = await getDocs(q)
        const document = first(result.docs)
        const documentId = document?.id
        if (!documentId) return
        const updateDocumentRef = doc(firestore, 'albums', documentId)
        await updateDoc(updateDocumentRef, classToPlain(data))
    }

    static async delete(id) {
        const collectionRef = collection(firestore, 'albums')
        const q = query(collectionRef, where('id', '==', id))
        const result = await getDocs(q)
        const document = first(result.docs)
        if (!document) return
        return await deleteDoc(document.ref)
    }
}
