import React from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'

export default function Visual() {
    setInterval(function () {
        $('section.visual').css({ height: $(window).outerHeight() })
    })

    return (
        <section className="visual">
            <video src="/assets/visualvideo_compressed.mp4" autoPlay muted loop playsInline className="visualVideo">
                해당 영상의 경로가 올바르지 않습니다.
            </video>
            <div className="inner-table">
                <div className="inner-tablecell">
                    <img src="/assets/logoVisual.png" alt="MUAH ENTERTAINMENT LOGO" className="logoVisual" />
                </div>
            </div>
            <div className="visualNav center">
                <label htmlFor="checkboxNav" className="toggleNav">
                    <img src="/assets/toggleNav_white.png" alt="메뉴" />
                </label>
                <ul>
                    <li>
                        <Link to="/company">ABOUT</Link>
                    </li>
                    <li>
                        <Link to="/album">ALBUM</Link>
                    </li>
                    <li>
                        <Link to="/artist">ARTIST</Link>
                    </li>
                    <li>
                        <Link to="/press">ARTICLE</Link>
                    </li>
                    {/* <li>
                        <a href="https://m.smartstore.naver.com/airbymuah" target="_blank" rel="noopener noreferrer">
                            SHOP
                        </a>
                    </li> */}
                    <li>
                        <Link to="/audition">AUDITION</Link>
                    </li>
                    <li>
                        <Link to="/contact">CONTACT</Link>
                    </li>
                </ul>
            </div>
        </section>
    )
}
