import React from 'react';
import CenterWrapper from '../../Components/CenterWrapper';
import SubPage from '../../Components/SubPage';
import { ContactModel } from '../../Models/ContactMode';
import { ContactService } from './../../Services/ContactService';

export default function Contact() {
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = Object.fromEntries(formData);
      const contact = new ContactModel(data);
      await ContactService.createContact(contact);
      alert('제출이 완료되었습니다.');
    } catch (e) {
      alert(`에러가 발생했습니다 ${e}`);
    }
  };

  return (
    <div className='contact'>
      <SubPage>
        <h2>CONTACT</h2>
        <CenterWrapper>
          <form onSubmit={handleSubmit}>
            <input type='text' name='name' placeholder='이름 및 상호명' />
            <input type='text' name='phone' placeholder='전화번호' />
            <input type='email' name='email' placeholder='이메일주소' />
            <textarea name='question' placeholder='문의 내용' />
            <button className='buttonSquare' type='submit'>
              SEND
            </button>
          </form>
        </CenterWrapper>
      </SubPage>
    </div>
  );
}
