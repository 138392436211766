import { uid } from 'uid';

// 아티스트 이력
export class ArtistHistoryModel {
  id: string = uid(20); // ID
  categoryName: string; // 카테고리명
  description: string; // 설명

  constructor(data) {
    Object.assign(this, data);
  }
}
