import { ArtistHistoryModel } from "./ArtistHistoryModel";
import { SnsModel } from "./SnsModel";

export class ArtistModel {
  id: string; // 아티스트 ID
  koreanName: string; // 아티스트 국문 닉네임
  englishName: string; // 아티스트 영어 닉네임
  order: number | null; // 노출 순서
  description: string; // 아티스트 설명
  squareImageUrl: string; // 아티스트 대표 이미지 (정방형)
  cardImageUrl: string; // 아티스트 대표 이미지 (카드형)
  historyList: ArtistHistoryModel[]; // 아티스트 이력 목록
  sns: SnsModel; // 아티스트 SNS
  modifiedAt: number; // 수정일시

  constructor(data) {
    Object.assign(this, data);
  }
}
