import React from 'react'
import AlbumList from '../../Components/Album/AlbumList'
import SubPage from '../../Components/SubPage'
import { useLocation } from "react-router-dom";

export default function Album() {

  const location = useLocation();
  const state = location.state;

  return (
    <div className="album">
      <SubPage>
        <h2>ALBUM</h2>
        <AlbumList artistName={(state && state.artistKoreanName) ? state.artistKoreanName : undefined}/>
      </SubPage>
    </div>
  )
}
