import { firestore } from '../Config/firebaseConfig';
import { collection, addDoc, getDocs, where, query, updateDoc, doc, orderBy, deleteDoc, limit } from 'firebase/firestore';
import { classToPlain } from 'class-transformer';
import { NoticeModel } from '../Models/NoticeModel';
import { first } from 'lodash';

export class NoticeService {
  static async create(notice: NoticeModel) {
    const collectionRef = collection(firestore, 'notices');
    return await addDoc(collectionRef, classToPlain(notice, { exposeUnsetFields: false }));
  }

  static async read(idx: string) {
    const collectionRef = collection(firestore, 'notices');
    const q = query(collectionRef, where('idx', '==', idx));
    const result = await getDocs(q);
    const data = result.docs.map((x) => x.data());
    return first(data);
  }

  static async readAll() {
    const collectionRef = collection(firestore, 'notices');
    const q = query(collectionRef, orderBy('publishedDate', 'desc'));
    const result = await getDocs(q);
    return result.docs.map((doc) => new NoticeModel(doc.data()));
  }

  static async getIdx() {
    const collectionRef = collection(firestore, 'notices');
    const q = query(collectionRef, orderBy('idx', 'desc'), limit(1));
    const result = await getDocs(q);
    return result.docs.map((doc) => doc.data().idx);
  }

  static async update(idx: string, data: NoticeModel) {
    const collectionRef = collection(firestore, 'notices');
    const q = query(collectionRef, where('idx', '==', idx));
    const result = await getDocs(q);
    const document = first(result.docs);
    const documentId = document.id;
    const updateDocumentRef = doc(firestore, 'notices', documentId);
    await updateDoc(updateDocumentRef, classToPlain(data));
  }

  static async delete(idx: string) {
    const collectionRef = collection(firestore, 'notices');
    const q = query(collectionRef, where('idx', '==', idx));
    const result = await getDocs(q);
    const document = first(result.docs);
    return await deleteDoc(document.ref);
  }
}
