import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { ArtistService } from '../Services/ArtistService'

export default function Nav() {
    const [artists, setArtists] = useState([])

    useEffect(() => {
        ArtistService.readAll().then((artists) => {
            setArtists(artists)
        })
    }, [])

    return (
        <nav className="nav">
            <label htmlFor="checkboxNav" className="bgNav" />
            <ul className="mainNav">
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'activeNav' : 'default')} to="/">
                        HOME
                    </NavLink>
                </li>
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'activeNav' : 'default')} to="/company">
                        ABOUT
                    </NavLink>
                    <ul className="subNav about">
                        <li>
                            <NavLink className={({ isActive }) => (isActive ? 'activeNav' : 'default')} to="/company">
                                COMPANY
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) => (isActive ? 'activeNav' : 'default')} to="/location">
                                LOCATION
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'activeNav' : 'default')} to="/album">
                        ALBUM
                    </NavLink>
                </li>
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'activeNav' : 'default')} to="/artist">
                        ARTIST
                    </NavLink>
                    <ul className="subNav artist">
                        {artists.map((artist, index) => {
                            return (
                                <li key={index}>
                                    <NavLink className={({ isActive }) => (isActive ? 'activeNav' : 'default')} to={`/artist/${artist.id}`}>
                                        <span className="artistEn">{artist.englishName}</span>
                                        <span className="artistKo">{artist.koreanName}</span>
                                    </NavLink>
                                </li>
                            )
                        })}
                    </ul>
                </li>
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'activeNav' : 'default')} to="/press">
                        ARTICLE
                    </NavLink>
                    <ul className="subNav article">
                        <li>
                            <NavLink className={({ isActive }) => (isActive ? 'activeNav' : 'default')} to="/press">
                                PRESS
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) => (isActive ? 'activeNav' : 'default')} to="/notice">
                                NOTICE
                            </NavLink>
                        </li>
                    </ul>
                </li>
                {/*
                <li>
                    <a href="https://m.smartstore.naver.com/airbymuah" target="_blank" rel="noopener noreferrer">
                        SHOP
                    </a>
                    <ModalAlert>조금만 기다려 주세요!</ModalAlert>
                </li>
                */}
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'activeNav' : 'default')} to="/audition">
                        AUDITION
                    </NavLink>
                </li>
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'activeNav' : 'default')} to="/contact">
                        CONTACT
                    </NavLink>
                </li>
            </ul>
        </nav>
    )
}
