import React from 'react'
import { Link } from 'react-router-dom'

export default function ButtonSquare(props) {
  return (
    <>
        <Link to={props.to} state={props?.state && {...props.state}}  className="buttonSquare">{props.children}</Link>
    </>
  )
}
