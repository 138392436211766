import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CenterWrapper from '../../Components/CenterWrapper'
import SubPage from '../../Components/SubPage'
import TitleSection from '../../Components/TitleSection'
import AlbumList from '../../Components/Album/AlbumList'
import PlatformButton from '../../Components/PlatformButton'
import None from '../../Components/None'
import { ArtistService } from '../../Services/ArtistService'
import { useArtistDetail } from '../../Hooks/hooksAboutDb'

export default function ArtistDetail() {

    const { artistDetail } = useArtistDetail()
    const artistDescription = artistDetail && artistDetail.description ? artistDetail.description?.replace(/\n/g, '<br />') : ''

    return (
        <div className="artistDetail">
            <SubPage>
                {artistDetail && (
                    <>
                        <div className="artistVisual" style={{ backgroundImage: `url(${artistDetail.squareImageUrl})` }}>
                            <div className="artistVisual-cell">
                                <CenterWrapper>
                                    <h2>
                                        <span className="en">{artistDetail.englishName}</span>
                                        <span className="ko">{artistDetail.koreanName}</span>
                                    </h2>
                                    <div>{artistDescription && <p dangerouslySetInnerHTML={{ __html: artistDescription }} />}</div>
                                </CenterWrapper>
                            </div>
                        </div>

                        <div className="artistContent">
                            <CenterWrapper>
                                <div className="platformButtonList">
                                    {artistDetail && artistDetail.sns && artistDetail.sns.instagramVisible === 'on' && (
                                        <PlatformButton url={artistDetail.sns.instagramUrl} filename="instagram" alt="인스타그램" />
                                    )}
                                    {artistDetail && artistDetail.sns && artistDetail.sns.youtubeVisible === 'on' && (
                                        <PlatformButton url={artistDetail.sns.youtubeUrl} filename="youtube" alt="유튜브" />
                                    )}
                                </div>
                                <div className="discography">
                                    {/* 해당 아티스트의 앨범만 노출 */}
                                    <AlbumList artistName={artistDetail.koreanName} />
                                    {/* 발매한 앨범이 없을 시 */}
                                    {/* <None /> */}
                                </div>

                                <div className="history">
                                    <TitleSection>{`History`}</TitleSection>
                                    {/* map 함수 */}
                                    {artistDetail.artistHistories.map((history, idx) => {
                                        const description = history && history.description ? history.description.replace(/\n/g, '<br />') : ''

                                        return (
                                            <div className="category" key={idx}>
                                                <h4>{history.categoryName}</h4>
                                                {description && <div className="content" dangerouslySetInnerHTML={{ __html: description }}></div>}
                                            </div>
                                        )
                                    })}
                                </div>
                            </CenterWrapper>
                        </div>
                    </>
                )}
            </SubPage>
        </div>
    )
}
