import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from 'react-js-pagination'
import CenterWrapper from '../../Components/CenterWrapper'
import PressCard from '../../Components/Press/PressCard'
import SubPage from '../../Components/SubPage'
import { PressService } from '../../Services/PressService.ts'
import { chunk } from 'lodash';


export default function Press() {
  const [presses, setPresses] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const pageSize = 9; // 페이지 크기 (한페이지에 몇개의 아이템을 노출시킬건가)
  const chunkedPresses = useMemo(() => {
    return chunk(presses, pageSize)[page - 1];
  }, [presses, page]); // 현재 페이지에 해당하는 데이터 목록
  useEffect(() => {
    PressService.readAll().then(presses => {
    setPresses(presses);
    })
}, [])
  return (
    <div className="pagePress">
      <SubPage>
        <h2>PRESS</h2>
        <div className="pressList">
          <CenterWrapper>
            {chunkedPresses && chunkedPresses.map( (press, index) => {
              return (
                <PressCard
                  url={press.url}
                  img={press.coverImageUrl}
                  title={press.title}
                  content={press.description}
                  date={press.publishedDate}
                  setPresses={setPresses}
                  totalCount={presses.length || 0}
                />
              )
            })}
          </CenterWrapper>
        </div>
        <Pagination activePage={page} itemsCountPerPage={pageSize} totalItemsCount={presses.length} pageRangeDisplayed={5} prevPageText={'‹'} nextPageText={'›'} onChange={setPage} />
      </SubPage>
    </div>
  )
}
