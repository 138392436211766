import React, { useState, useEffect } from 'react';
import TitleSection from '../../../Components/TitleSection'
import PressCard from '../../../Components/Press/PressCard';
import ButtonSquare from '../../../Components/Button/ButtonSquare'
import CenterWrapper from '../../../Components/CenterWrapper'
import { PressService } from '../../../Services/PressService.ts'

export default function PressSection() {
  const [presses, setPresses] = useState([]);
  useEffect(() => {
    PressService.readAll().then(presses => {
    setPresses(presses);
    })
}, [])
  return (
    <section className="press">
        <CenterWrapper>
          <TitleSection>Press</TitleSection>
        </CenterWrapper>
        <div className="pressList">
          <CenterWrapper>
            {presses.map( (press, index) => {
              return (
                <PressCard
                  url={press.url}
                  img={press.coverImageUrl}
                  title={press.title}
                  content={press.description}
                  date={press.publishedDate}
                  setPresses={setPresses}
                />
              )
            })}
          </CenterWrapper>
        </div>
        <ButtonSquare to="/press">MORE</ButtonSquare>
    </section>
  )
}
