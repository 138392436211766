export class NoticeModel {
  idx: num;
  title: string; //공지 제목
  publishedDate: string; // 발행일
  description: string; // 내용

  constructor(data) {
    Object.assign(this, data);
  }
}
