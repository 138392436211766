import None from '../../Components/None'
import React, { useState, useEffect } from 'react'
import CenterWrapper from '../CenterWrapper'
import AlbumCard from './AlbumCard'
import { AlbumService,  } from '../../Services/AlbumService'

export default function AlbumList({artistName}) {

    const [albums, setAlbums] = useState([])

    useEffect(() => {    
        artistName ? AlbumService.readAllByArtistName(artistName).then(setAlbums)
                    : AlbumService.readAll().then(setAlbums)
    }, [artistName])

    return (
        <CenterWrapper>
            <div className="albumList">
                {albums.length !== 0 && 
                albums.map((album, index) => 
                    <AlbumCard index={index} url={`/album/${album.id}`} img={album.coverImageUrl} title={`${album.name} - ${album.albumArtistName}`} />
                )} 
                {albums.length === 0 &&
                <None />
                }
            </div>
        </CenterWrapper>
    )
}