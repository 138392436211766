import React from 'react'
import { Link } from 'react-router-dom'

export default function AlbumCard(props) {
  return (
    <div className="albumCard" key={props.index}>
        <Link to={props.url}>
            <figure>
                <img src={props.img} alt={props.title} />
            </figure>
            <h4>{props.title}</h4>
        </Link>
    </div>
  )
}
