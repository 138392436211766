import { instanceToPlain } from 'class-transformer';

export class ContactModel {
  name: string;
  email: string;
  phone: string;
  question: string;

  constructor(data) {
    Object.assign(this, data);
  }

  toJSON() {
    return instanceToPlain(this, { exposeUnsetFields: false });
  }
}
