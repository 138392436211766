import { firestore } from '../Config/firebaseConfig';
import { collection, getDocs, where, query, updateDoc, doc } from 'firebase/firestore';
import { classToPlain } from 'class-transformer';
import { AuditionModel } from '../Models/AuditionModel';
import { first } from 'lodash';

export class AuditionService {
  static async read() {
    const collectionRef = collection(firestore, 'audition');
    const q = query(collectionRef, where('id', '==', 'audition'));
    const result = await getDocs(q);
    const data = result.docs.map((x) => x.data());
    return first(data);
  }

  static async update(data) {
    const collectionRef = collection(firestore, 'audition');
    const q = query(collectionRef, where('id', '==', 'audition'));
    const result = await getDocs(q);
    const document = first(result.docs);
    const documentId = document.id;
    const updateDocumentRef = doc(firestore, 'audition', documentId);
    await updateDoc(updateDocumentRef, classToPlain(data));
  }
}
