import { ArtistModel } from './ArtistModel'
import { StreamingModel } from './StreamingModel'

export class AlbumModel {
    id // 앨범ID
    name // 앨범명
    albumArtistName // 앨범 아티스트명 (앨범에는 아티스트의 이름이 그때그때 다르게 들어갈 수 있어서 밑에 아티스트 정보와는 별개로 필드가 하나 더 필요하다.)
    releasedDate // 발매일
    coverImageUrl // 커버 이미지
    description // 앨범 설명
    artist // 아티스트 정보
    streaming // 스트리밍 사이트 정보

    constructor(data) {
        Object.assign(this, data)
    }
}
