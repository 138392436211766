import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import TitleSection from '../../../Components/TitleSection'
import CenterWrapper from '../../../Components/CenterWrapper'
import ArtistCard from '../../../Components/ArtistCard'
import { ArtistService } from '../../../Services/ArtistService'

setInterval(function () {
    $('.leftText li:nth-child(1)').mouseover(function () {
        $('.rightImg li:nth-child(1) a').addClass('active')
    })
    $('.leftText li:nth-child(1)').mouseout(function () {
        $('.rightImg li:nth-child(1) a').removeClass('active')
    })

    $('.leftText li:nth-child(2)').mouseover(function () {
        $('.rightImg li:nth-child(2) a').addClass('active')
    })
    $('.leftText li:nth-child(2)').mouseout(function () {
        $('.rightImg li:nth-child(2) a').removeClass('active')
    })

    $('.leftText li:nth-child(3)').mouseover(function () {
        $('.rightImg li:nth-child(3) a').addClass('active')
    })
    $('.leftText li:nth-child(3)').mouseout(function () {
        $('.rightImg li:nth-child(3) a').removeClass('active')
    })
})

export default function ArtistSection() {
    const [artists, setArtists] = useState([])

    useEffect(() => {
        ArtistService.readAll().then((artists) => {
            setArtists(artists)
        })
    }, [])

    return (
        <section className="artists">
            <CenterWrapper>
                <TitleSection>Artists</TitleSection>
            </CenterWrapper>

            <CenterWrapper>
                <div className="leftText">
                    <ul>
                        {artists.map((artist, index) => {
                            return (
                                <li>
                                    <Link to={`/artist/${artist.id}`}>{artist.englishName}</Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="rightImg">
                    <ul className="artistList">
                        {artists.map((artist, index) => {
                            return <ArtistCard nameLower={`${artist.id}`} nameUpper={artist.englishName} nameKo={artist.koreanName} />
                        })}
                    </ul>
                </div>
            </CenterWrapper>
        </section>
    )
}
