import React, { useState } from 'react'

export default function ModalBasic({ children }) {
  const [modal, setModal] = useState(true);

  return (
    <>
      {
        modal === true
        ?
        <div className="modal">
          <div className="modalBg"  onClick={() => setModal(false)} />
          <div className="modalBox">
            <button className="close" onClick={() => setModal(false)}>
                <img src="/assets/closeModal.png" alt="닫기" />
            </button>
            <div dangerouslySetInnerHTML={{__html : children}}>
                {/* {children} */}
            </div>
          </div>
        </div>
        :
        null
      }
    </>
  )
}
