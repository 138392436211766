import React from 'react'
import Header from './Header'

export default function SubPage({children}) {
  return (
    <div className="subpage">
        <Header />
        {children}
    </div>
  )
}
