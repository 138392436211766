import { firestore } from '../Config/firebaseConfig';
import { collection, addDoc, getDocs, where, query, updateDoc, doc, orderBy, deleteDoc, limit } from 'firebase/firestore';
import { classToPlain } from 'class-transformer';
import { PressModel } from '../Models/PressModel';
import { first } from 'lodash';

export class PressService {
  static async create(press: PressModel) {
    const collectionRef = collection(firestore, 'presses');
    return await addDoc(collectionRef, classToPlain(press, { exposeUnsetFields: false }));
  }

  static async read(idx: string) {
    const collectionRef = collection(firestore, 'presses');
    const q = query(collectionRef, where('idx', '==', idx));
    const result = await getDocs(q);
    const data = result.docs.map((x) => x.data());
    return first(data);
  }

  static async readAll() {
    const collectionRef = collection(firestore, 'presses');
    const q = query(collectionRef, orderBy('publishedDate', 'desc'));
    const result = await getDocs(q);
    return result.docs.map((doc) => new PressModel(doc.data()));
  }

  static async getIdx() {
    const collectionRef = collection(firestore, 'presses');
    const q = query(collectionRef, orderBy('idx', 'desc'), limit(1));
    const result = await getDocs(q);
    return result.docs.map((doc) => doc.data().idx);
  }

  static async update(idx: string, data: PressModel) {
    const collectionRef = collection(firestore, 'presses');
    const q = query(collectionRef, where('idx', '==', idx));
    const result = await getDocs(q);
    const document = first(result.docs);
    if (!document) return;
    const documentId = document?.id;
    const updateDocumentRef = doc(firestore, 'presses', documentId);
    await updateDoc(updateDocumentRef, classToPlain(data));
  }

  static async delete(idx: string) {
    const collectionRef = collection(firestore, 'presses');
    const q = query(collectionRef, where('idx', '==', idx));
    const result = await getDocs(q);
    const document = first(result.docs);
    if (!document) return;
    return await deleteDoc(document?.ref);
  }
}
