import React from 'react'
import CenterWrapper from './CenterWrapper'
import Logo from './Logo'
import Nav from './Nav'

export default function Header(props) {
  return (
    <header className={`header ${props.addedclass}`}>
      <CenterWrapper>
        <label htmlFor="checkboxNav" className="toggleNav">
          <img src="/assets/toggleNav.png" alt="메뉴" />
        </label>
        <Logo className="headerLogo" />
        <input type="checkbox" id="checkboxNav" />
        <Nav />
      </CenterWrapper>
    </header>
  )
}
