import React from 'react'
import SubPage from '../../Components/SubPage'
import Pbr from '../../Components/Pbr'

export default function Company() {
    return (
        <div className="company">
            <SubPage>
                <div className="visual" style={{ backgroundImage: 'url(/assets/tijs-van-leur-Qnlp3FCO2vc.jpg)' }}>
                    <img src="/assets/logoVisual.png" alt="MUAH ENTERTAINMENT" />
                </div>
                <div className="content center">
                    <h2>
                        We Create Brand-New K-Contents,
                        <br />
                        MUAH Entertainment., Inc.
                    </h2>

                    <div className="text">
                        <p>
                            2021년 문화체육관광부 예비사회적기업으로 지정된 무아엔터테인먼트는 <Pbr />
                            사회적 가치와 지속 가능한 K-Contents를 실현합니다.
                        </p>
                        <p>
                            아티스트 매니지먼트, 컨텐츠 퍼블리싱, 공연물 기획/운영 등을 통해 <Pbr />
                            우수한 컨텐츠를 가진 뮤지션들의 지속가능한 예술활동을 전방위적으로 지원하며
                            <Pbr />
                            음원 제작부터 유통, 매니지먼트와 투자에 이르는 다양한 방식으로 그들과 동행합니다.
                        </p>
                    </div>

                    <ul className="businessList">
                        <li>
                            <figure>
                                <img src="/assets/business1.png" alt="아티스트 매니지먼트 및 제작" />
                            </figure>

                            <div className="desc">
                                <h4>
                                    아티스트 <Pbr />
                                    매니지먼트 및 제작
                                </h4>
                                <p>대중음악과 전통음악을 아울러 좋은 음악이라는 본연의 가치에 집중해 뛰어난 음악성을 지닌 실력있는 아티스트를 발굴하고 육성합니다.</p>
                            </div>
                        </li>
                        <li>
                            <figure>
                                <img src="/assets/business2.png" alt="컨텐츠 프로바이딩 및 퍼블리싱" />
                            </figure>
                            <div className="desc">
                                <h4>
                                    컨텐츠 프로바이딩 <Pbr />및 퍼블리싱
                                </h4>
                                <p>국악, K-POP 등 한국음악을 기반으로 다양한 장르의 가장 새롭고 신선한 아티스트들의 양질의 뮤직컨텐츠를 전세계 곳곳에 소개합니다.</p>
                            </div>
                        </li>
                        <li>
                            <figure>
                                <img src="/assets/business3.png" alt="콘서트, 페스티벌 등 퍼포먼스 플래닝" />
                            </figure>

                            <div className="desc">
                                <h4>
                                    콘서트, 페스티벌 등 <Pbr />
                                    퍼포먼스 플래닝
                                </h4>
                                <p>우수한 콘텐츠 제작 능력을 바탕으로 B2B, B2G를 아우르는 다양한 클라이언트들의 니즈에 맞는 음악 기반의 행사를 제작 및 운영합니다.</p>
                            </div>
                        </li>
                    </ul>

                    <div className="partners">
                        <h3>
                            <span className="en">PARTNERS</span>
                            <span className="ko">유관사</span>
                        </h3>
                        <ul>
                            <li>
                                <img src="/assets/logo-partners-gwangwang.png" alt="한국관광공사" />
                            </li>
                            <li>
                                <img src="/assets/logo-partners-SK.png" alt="SK Broadband" />
                            </li>
                            <li>
                                <img src="/assets/logo-partners-mcst.png" alt="문화체육관광부" />
                            </li>
                            <li>
                                <img src="/assets/logo-partners-kams.png" alt="예술경영지원센터" />
                            </li>
                            <li>
                                <img src="/assets/logo-partners-SE.png" alt="한국사회적기업진흥원" />
                            </li>
                            <li>
                                <img src="/assets/logo-partners-kaist.png" alt="카이스트" />
                            </li>
                            <li>
                                <img src="/assets/logo-partners-dima.png" alt="동아방송예술대학교" />
                            </li>
                            <li>
                                <img src="/assets/logo-partners-impactSquare.png" alt="Impact Square" />
                            </li>
                            <li>
                                <img src="/assets/logo-partners-sovac.png" alt="SOVAC" />
                            </li>
                            <li>
                                <img src="/assets/logo-partners-wt.png" alt="함께일하는재단" />
                            </li>
                            <li>
                                <img src="/assets/logo-partners-hamjae.png" alt="함재" />
                            </li>
                            <li>
                                <img src="/assets/logo-partners-danstruct.png" alt="댄스트럭트" />
                            </li>
                            <li>
                                <img src="/assets/logo-partners-composigners.png" alt="Composigners" />
                            </li>
                            <li>
                                <img src="/assets/logo-partners-muzit.png" alt="뮤지트" />
                            </li>
                        </ul>
                    </div>
                </div>
            </SubPage>
        </div>
    )
}
