import { Routes, Route } from 'react-router-dom'
import Footer from './Components/Footer'
import Home from './Pages/01_Home/Home'
import Company from './Pages/02_About/Company'
import Location from './Pages/02_About/Location'
import Album from './Pages/03_Album/Album'
import AlbumDetail from './Pages/03_Album/AlbumDetail'
import Artist from './Pages/04_Artist/Artist'
import ArtistDetail from './Pages/04_Artist/ArtistDetail'
import Press from './Pages/05_Press/Press'
import Notice from './Pages/06_Notice/Notice'
import NoticeDetail from './Pages/06_Notice/NoticeDetail'
import Audition from './Pages/07_Audition/Audition'
import Contact from './Pages/08_Contact/Contact'
import NotFound from './Pages/NotFound'
import './Styles/master.scss'

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/company/*" element={<Company />}></Route>
                <Route path="/location/*" element={<Location />}></Route>
                <Route path="/album/*" element={<Album />}></Route>
                <Route path="/album/:id" element={<AlbumDetail />}></Route>
                <Route path="/artist/*" element={<Artist />}></Route>
                <Route path="/artist/:id" element={<ArtistDetail />}></Route>
                <Route path="/press/*" element={<Press />}></Route>
                <Route path="/notice/*" element={<Notice />}></Route>
                <Route path="/notice/:id" element={<NoticeDetail />}></Route>
                <Route path="/audition/*" element={<Audition />}></Route>
                <Route path="/contact/*" element={<Contact />}></Route>
                <Route path="*" element={<NotFound />}></Route>
            </Routes>
            <Footer />
        </div>
    )
}

export default App
