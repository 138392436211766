import React from 'react';
import $ from 'jquery';
import Header from '../../Components/Header';
import Visual from './Section/Visual';
import Introduction from './Section/Introduction';
import BusinessArea from './Section/BusinessArea';
import ArtistSection from './Section/ArtistSection';
import AlbumSection from './Section/AlbumSection';
import PressSection from './Section/PressSection';

setInterval(function () {
  if ($(window).scrollTop() >= $(window).outerHeight() - 116) {
    $('.header.transition').css({ top: 0 });
  } else {
    $('.header.transition').css({ top: '-116px' });
  }
});

export default function Home() {
  return (
    <div className='mainpage'>
      <Header addedclass='transition' />
      <Visual />
      <Introduction />
      <BusinessArea />
      <ArtistSection />
      <AlbumSection />
      <PressSection />
    </div>
  );
}
