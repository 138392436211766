import React from 'react'
import ButtonSquare from '../../../Components/Button/ButtonSquare'
import Mbr from '../../../Components/Mbr'

export default function BusinessArea() {
  return (
    <section className="businessArea">
        <h3>BUSINESS AREA</h3>
        <ul className="businessList">
            <li>
                <img src="/assets/business1.png" alt="아티스트 매니지먼트 및 제작" />
                <h4>
                    아티스트 <br />매니지먼트 <Mbr />및 제작
                </h4>
            </li>
            <li>
                <img src="/assets/business2.png" alt="컨텐츠 프로바이딩 및 퍼블리싱" />
                <h4>
                컨텐츠 <Mbr />프로바이딩 <br />및 퍼블리싱
                </h4>
            </li>
            <li>
                <img src="/assets/business3.png" alt="콘서트, 페스티벌 등 퍼포먼스 플래닝" />
                <h4>
                콘서트, <Mbr />페스티벌 등 <br />퍼포먼스 플래닝
                </h4>
            </li>
        </ul>
        <ButtonSquare to="/company">MORE</ButtonSquare>
    </section>
  )
}
