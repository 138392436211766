import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import CenterWrapper from '../../Components/CenterWrapper'
import SubPage from '../../Components/SubPage'
import NoticeListItem from './NoticeListItem'
import { NoticeService } from '../../Services/NoticeService'
import { chunk } from 'lodash'

export default function Notice() {
    const [notices, setNotices] = useState([]) // 전체 공지 목록
    const [page, setPage] = useState(1)
    const navigate = useNavigate()
    const pageSize = 10 // 페이지 크기 (한페이지에 몇개의 아이템을 노출시킬건가)
    const chunkedNotices = useMemo(() => {
        return chunk(notices, pageSize)[page - 1]
    }, [notices, page]) // 현재 페이지에 해당하는 데이터 목록

    useEffect(() => {
        NoticeService.readAll().then((notices) => {
            setNotices(notices)
        })
    }, [])

    return (
        <div>
            <SubPage>
                <h2>NOTICE</h2>
                <CenterWrapper>
                    <ul className="noticeList">
                        {chunkedNotices &&
                            chunkedNotices.map((notice, index) => {
                                return <NoticeListItem url={`/notice/${notice.idx}`} title={notice.title} date={notice.publishedDate} setNotices={setNotices} totalCount={notices.length || 0} />
                            })}
                    </ul>
                    <Pagination activePage={page} itemsCountPerPage={pageSize} totalItemsCount={notices.length} pageRangeDisplayed={5} prevPageText={'‹'} nextPageText={'›'} onChange={setPage} />
                </CenterWrapper>
            </SubPage>
        </div>
    )
}
