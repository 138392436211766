import React from 'react'

export default function PressCard(props) {
  return (
    <div className="pressCard">
        <a href={`${props.url}`} target="_blank" rel="noopener noreferrer">
            <figure style={{backgroundImage: `url(${props.img})`}} />
            <div className="text">
              <h4 className="ellipsis-2">{props.title}</h4>
              <p className="ellipsis-3">{props.content}</p>
              <span className="date">{props.date}</span>
            </div>
        </a>
    </div>
  )
}
