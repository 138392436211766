// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAbmT1yiOaTgpqm6-UjjLQsGGXAwq0Lo68",
  authDomain: "muah-entertainment.firebaseapp.com",
  projectId: "muah-entertainment",
  storageBucket: "muah-entertainment.appspot.com",
  messagingSenderId: "2631502631",
  appId: "1:2631502631:web:0e375e3dbc1af372df7702",
  measurementId: "G-NNH6BKZK1N",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage();
export const analytics = getAnalytics(firebaseApp);
