import { PressMetaDataModel } from './PressMetaDataModel'

export class PressModel {
    idx
    type // 기사 등록 구조 (직접등록, 불러오기)
    title // 기사 제목
    url // 기사 링크
    description // 기사 내용
    coverImageUrl // 커버 이미지
    publishedDate // 기사 발행일

    constructor(data) {
        Object.assign(this, data)
    }

    static fromMetaData(metaData) {
        return new PressModel({
            type: 'import',
            title: metaData?.title,
            description: metaData?.description,
            url: metaData?.url,
            coverImageUrl: metaData?.image,
            publishedDate: metaData?.publishedDate,
        })
    }
}
